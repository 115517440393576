import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { instanceLocal } from '@/api'

import { setAuth } from './authReducer'

interface InitialStateType {
	loading: boolean
	init: boolean
	isShowMenu: boolean
	typeApp: 'mob' | 'desk'
	callModalIsOpen: boolean
	typeCall: 'operator' | 'terminal' | null
	terminal_id: string
	callRecordId: string | null
}

const initialState: InitialStateType = {
	loading: true,
	init: false,
	isShowMenu: false,
	typeApp: 'desk',
	callModalIsOpen: false,
	typeCall: null,
	terminal_id: '',
	callRecordId: null,
}

export const appReducer = createSlice({
	name: 'app',
	initialState,
	reducers: {
		loadingStatus: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		setInit: (state, action: PayloadAction<boolean>) => {
			state.init = action.payload
		},
		setTypeApp: (state, action: PayloadAction<'mob' | 'desk'>) => {
			state.typeApp = action.payload
		},
		isShowMenu: (state, action: PayloadAction<boolean>) => {
			state.isShowMenu = action.payload
		},
		isCallModalIsOpen: (state, action: PayloadAction<boolean>) => {
			state.callModalIsOpen = action.payload
		},
		setTypeCall: (
			state,
			action: PayloadAction<'operator' | 'terminal' | null>
		) => {
			state.typeCall = action.payload
		},
		setTerminalId: (state, action: PayloadAction<string>) => {
			state.terminal_id = action.payload
		},
		setCallRecordId: (state, action: PayloadAction<string | null>) => {
			state.callRecordId = action.payload
		},
	},
})

export const {
	loadingStatus,
	setInit,
	isShowMenu,
	setTypeApp,
	isCallModalIsOpen,
	setTypeCall,
	setTerminalId,
	setCallRecordId,
} = appReducer.actions

export const initializeApp = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		dispatch(setAuth(true))
		const { data } = await instanceLocal.get('configuration/frontend')
		dispatch(setTerminalId(data.terminal_id))
	} catch (err: any) {
		console.error(err)
		dispatch(setTerminalId(''))
	} finally {
		dispatch(setInit(true))
		dispatch(loadingStatus(false))
	}
}

export default appReducer.reducer
