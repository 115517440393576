import { instance, instanceLocal } from './axios'

export const authAPI = {
	user() {
		return instanceLocal.get(`user/`)
	},
	me() {
		return instanceLocal.get(`auth/me`)
	},

	login(login: string, password: string) {
		return instanceLocal.post('auth/login', {
			login,
			password,
		})
	},

	logOut() {
		return instanceLocal.post(`auth/logout`)
	},
}

export const payAPI = {
	payCard({
		operation_name,
		receipt_num,
		amount,
		client_mail,
	}: {
		operation_name: string
		receipt_num?: number
		amount: string
		client_mail?: string
	}) {
		return instanceLocal.post(
			`/payment/terminal:process?operation_name=${operation_name}&operation_type=1&receipt_num=${receipt_num ? receipt_num : 1}&amount=${+amount}&client_mail=${client_mail ? client_mail : null}`
		)
	},
	getQr() {
		return instanceLocal.post(`/payment/qr:get`)
	},
}
