import axios from 'axios'
import { ARM_HOST, ASTIP_APP } from 'configs/url'

export const instance = axios.create({
	withCredentials: true,
	baseURL: ARM_HOST,
})

export const instanceLocal = axios.create({
	withCredentials: true,
	baseURL: ASTIP_APP,
})
