import { QRCode } from 'antd'
import { useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'
import { text } from 'stream/consumers'

import { Advertising } from '@/components'

//@ts-ignore
import video from '../assets/video/dogaz.webm'

export const Gasification = () => {
	const [link, setLink] = useState('-')
	const navigate = useNavigate()
	const [qrText, setQrCode] = useState('-')

	return (
		<div className={'advertising'}>
			<button className="back-btn" onClick={() => navigate(-1)}>
				Вернуться назад
			</button>
			<h1 className="title">Догазификация1</h1>
			{/* <video src={video}></video> */}
			<div className="advertising__inner">
				<div className="advertising__video">
					<ReactPlayer
						url={video}
						width="100%"
						playing
						height="100%"
						volume={1}
					/>
				</div>

				<div className="advertising__qr-inner">
					<QRCode value={qrText} bgColor="#fff" size={250} />
					<QRCode value={qrText} bgColor="#fff" size={250} />
				</div>
			</div>
		</div>
	)
}
