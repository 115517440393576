import cn from 'clsx'
import React from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

//@ts-ignore
import video from '../../assets/video/lk.webm'

interface Props {
	title: string
	videoUrl?: string
	className?: string
}

export const Advertising: React.FC<Props> = ({
	title,
	videoUrl,
	className,
}) => {
	const navigate = useNavigate()

	return (
		<div className={cn('advertising', className)}>
			<button className="back-btn" onClick={() => navigate(-1)}>
				Вернуться назад
			</button>
			<h1 className="title">{title}</h1>
			{/* <video src={video}></video> */}
			<div className="advertising__video">
				<ReactPlayer
					url={video}
					width="100%"
					playing
					height="100%"
					volume={1}
				/>
			</div>
		</div>
	)
}
