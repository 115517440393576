import { Spin } from 'antd'
import axios from 'axios'
import { ARM_HOST, HOST_WEBRTC } from 'configs/url'
import { useEffect, useRef, useState } from 'react'

import {
	isCallModalIsOpen,
	setCallRecordId,
	setTypeCall,
	useAppDispatch,
	useAppSelector,
} from '@/store'

interface CallModalProps {
	open: boolean
	close: () => void
	addingClass: () => string
	setOpenModal: (value: boolean) => void
}

export function CallModal({
	close,
	open,
	addingClass,
	setOpenModal,
}: CallModalProps) {
	const { typeCall, callRecordId, terminal_id } = useAppSelector(
		(state) => state.app
	)
	const dispatch = useAppDispatch()
	const [small, setSmall] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [hasError, setHasError] = useState<boolean>(false)
	const iframeRef = useRef<HTMLIFrameElement>(null)

	useEffect(() => {
		if (open) {
			const listener = async (event: MessageEvent) => {
				console.log(event.data.type)

				if (event.data && event.data.type === 'no-answer') {
					dispatch(isCallModalIsOpen(false))
					setOpenModal(true)
				}
				if (event.data && event.data.type === 'auto-close-iframe') {
					dispatch(isCallModalIsOpen(false))

					await axios.patch(
						`${ARM_HOST}/terminal_call/end_call_from_webrtc`,
						null,
						{
							params: {
								CallRecordId: callRecordId,
							},
						}
					)
				}
				if (event.data && event.data.type === 'change-size-window') {
					setSmall(event.data.size)
				}
				if (event.data && event.data.type === 'room-deleted') {
					dispatch(isCallModalIsOpen(false))
				}
			}

			window.addEventListener('message', listener)
			return () => {
				window.removeEventListener('message', listener)
			}
		}
	}, [open, dispatch, callRecordId])

	useEffect(() => {
		return () => {
			dispatch(setTypeCall(null))
		}
	}, [dispatch])

	useEffect(() => {
		const makeCall = async () => {
			try {
				if (typeCall === 'terminal') {
					await axios.post(
						`${ARM_HOST}/terminal_call/receive_call`,
						{
							terminalId: terminal_id,
							roomId: terminal_id,
						},
						{
							headers: {
								'Content-Type': 'application/json',
							},
						}
					)
				} else if (typeCall === null) {
					const { data } = await axios.patch(
						`${ARM_HOST}/terminal_call/accept_terminal_call`,
						{},
						{
							params: {
								TerminalId: terminal_id,
								roomId: terminal_id,
							},
						}
					)
					dispatch(setCallRecordId(data.callRecordId))
				}
			} catch (error) {
				console.log(error)
			}
		}

		makeCall()
	}, [typeCall])

	const handleIframeLoad = () => {
		setIsLoading(false)
		setHasError(false)
		if (iframeRef.current && iframeRef.current.contentWindow) {
			iframeRef.current.contentWindow.postMessage(
				{
					type: 'updateStyles',
					selector: '.VideoContainerMy',
					styles: { display: 'none' },
				},
				'*'
			)
		}
	}

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (isLoading) {
				setIsLoading(false)
				setHasError(true)
			}
		}, 30000)

		return () => clearTimeout(timeoutId)
	}, [isLoading])

	useEffect(() => {
		let errorTimeout: NodeJS.Timeout
		if (hasError) {
			errorTimeout = setTimeout(() => {
				close()
			}, 10000) // 10 секунд
		}
		return () => clearTimeout(errorTimeout)
	}, [hasError, close])

	return (
		<div
			className={`call-modal ${open && '_open'} ${small && '_small'} ${addingClass()}`}
		>
			<div className="call-modal__inner">
				<div className="call-modal__video">
					{isLoading && !hasError && (
						<div style={{ textAlign: 'center', padding: '20px' }}>
							<Spin size="large" tip="Загрузка..." />
						</div>
					)}
					{hasError ? (
						<div style={{ color: 'red', textAlign: 'center', padding: '20px' }}>
							Ошибка загрузки. Пожалуйста, попробуйте позже.
						</div>
					) : (
						<iframe
							src={`${HOST_WEBRTC}/${terminal_id}`}
							style={{
								width: '100%',
								height: '100%',
								display: isLoading ? 'none' : 'block',
							}}
							allow="camera; microphone; fullscreen"
							onLoad={handleIframeLoad}
							onError={() => setHasError(true)}
							ref={iframeRef}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
