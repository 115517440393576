import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

import { CALL_PATH, MAIN_PATH } from '../../utils/const'

const exemptedRoutes = [MAIN_PATH, CALL_PATH]

export function TimeoutProvider({ children }: { children: JSX.Element }) {
	const navigate = useNavigate()
	const location = useLocation()
	const timeoutRef = useRef<NodeJS.Timeout>()
	const isCall = useAppSelector((state) => state.app.callModalIsOpen)

	useEffect(() => {
		if (exemptedRoutes.includes(location.pathname)) return
		const handleWindowEvents = () => {
			clearTimeout(timeoutRef.current)
			if (!isCall) {
				timeoutRef.current = setTimeout(() => {
					navigate(MAIN_PATH)
				}, 120000)
			}
		}

		// listen for specific window events to ensure the user is still active
		window.addEventListener('mousemove', handleWindowEvents)
		window.addEventListener('keydown', handleWindowEvents)
		window.addEventListener('click', handleWindowEvents)
		window.addEventListener('scroll', handleWindowEvents)

		handleWindowEvents()

		// cleanup function
		return () => {
			window.removeEventListener('mousemove', handleWindowEvents)
			window.removeEventListener('keydown', handleWindowEvents)
			window.removeEventListener('click', handleWindowEvents)
			window.removeEventListener('scroll', handleWindowEvents)
		}
	}, [navigate, location.pathname, isCall])

	return children
}
