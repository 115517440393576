import { Modal, notification } from 'antd'
import { instance } from 'api/axios'
import cn from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import Keyboard from 'react-simple-keyboard'
import { useAppSelector } from 'store/hooks'
import {
	buttonTheme,
	displayLettersName,
	displaySt,
	layoutLettersName,
	layoutSt,
	layoutStPhone,
} from 'utils/typeKeyBoard'
import * as Yup from 'yup'

interface Props {
	openModal: boolean
	setOpenModal: (value: boolean) => void
}

type InputName = 'phone' | 'personalNumber' | 'name'

interface Form {
	phone: string
	personalNumber: string
	name: string
}

const SignupSchema = Yup.object().shape({
	personalNumber: Yup.string()
		.min(8, 'Не менее 8 символов')
		.max(12, 'Не более 12 символов')
		.matches(/^([0-9]{8}|[0-9]{12})$/i, '8 или 12 цифр')
		.notRequired(),
	phone: Yup.string()
		.matches(
			/^((\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/gm,
			'Неверный номер телефона'
		)
		.min(10, 'Не менее 10 символов'),
	name: Yup.string().max(255, 'Не более 255 символов'),
})

export const FeedbackForm = ({ openModal, setOpenModal }: Props) => {
	const { terminal_id } = useAppSelector((state) => state.app)
	const [inputs, setInputs] = useState<any>({})
	const [inputName, setInputName] = useState<InputName>('personalNumber')
	const [typeKeyboard, setTypeKeyboard] = useState<'numbers' | 'text'>(
		'numbers'
	)
	const [layoutName, setLayoutName] = useState<'default' | 'shift'>('default')

	const keyboard = useRef<any>()

	const formik = useFormik<Form>({
		initialValues: {
			personalNumber: '',
			phone: '',
			name: '',
		},
		validationSchema: SignupSchema,
		onSubmit: () => {},
	})

	const saveValue = (inputs: any) => {
		if (inputName !== 'phone') {
			formik.setValues({
				...inputs,
				phone: inputs['phone']?.replace(
					/^\+?7?(\d{3})(\d{3})(\d{2})(\d{2})$/,
					'+7 ($1) $2-$3-$4'
				),
			})
			setInputs({
				...inputs,
				phone: inputs['phone']?.replace(
					/^\+?7?(\d{3})(\d{3})(\d{2})(\d{2})$/,
					'+7 ($1) $2-$3-$4'
				),
			})
		} else if (inputName === 'phone') {
			formik.setValues({
				...inputs,
				phone: inputs['phone']
					?.trim()
					?.replace('(', '')
					?.replace(')', '')
					?.replaceAll('-', '')
					?.replaceAll(' ', ''),
			})
			setInputs({
				...inputs,
				phone: inputs['phone']
					?.trim()
					?.replace('(', '')
					?.replace(')', '')
					?.replaceAll('-', '')
					?.replaceAll(' ', ''),
			})
		}
	}

	const onChangeAll = (inputs: any) => saveValue(inputs)

	const onKeyPress = async (button: any) => {
		if (button === '{shift}') {
			setLayoutName((prev) => (prev === 'default' ? 'shift' : 'default'))
			keyboard.current.setOptions({
				layoutName: layoutName === 'default' ? 'shift' : 'default',
			})
		}
		if (button === '{enter}') {
			const values = formik.values
			const errorsForm = formik.errors

			if (formik.isValid && values['phone'] && values['name']) {
				try {
					const { name, personalNumber, phone } = formik.values
					await instance.post('/appeals/feedback_create', {
						terminalId: terminal_id,
						abonentName: name,
						abonentPhoneNumber: phone,
						abonentPerconalAcc: personalNumber,
					})
					notification.success({
						message: 'Успешно',
						description: 'Сообщение успешно отправлено',
					})
					formik.resetForm()
					setOpenModal(false)
				} catch (error) {
					console.log(error)
					notification.error({
						message: 'Ошибка',
						description: 'Сообщение не отправлено',
					})
				}
			} else {
				const errors: any = {}
				if (!values.phone) {
					errors.phone = 'Поле обязательно для заполнение'
				}

				if (!values.name) {
					errors.name = 'Поле обязательно для заполнение'
				}

				formik.setErrors({ ...errorsForm, ...errors })
			}
		}
	}

	const onChangeInput = (event: any) => {
		const inputVal = event?.target?.value
		setInputs({
			...inputs,
			[inputName]: inputVal,
		})
		formik.setValues({
			...inputs,
			[inputName]: inputVal,
		})

		keyboard.current.setInput(inputVal)
	}

	const getInputValue = (inputName: any) => {
		return inputs[inputName] || ''
	}

	useEffect(() => {
		saveValue(inputs)

		if (inputName === 'name') {
			setTypeKeyboard('text')
		} else {
			setTypeKeyboard('numbers')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputName])

	useEffect(() => {
		setInputName('personalNumber')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Modal
			open={openModal}
			onCancel={() => setOpenModal(false)}
			centered
			width={'100%'}
			footer={null}
		>
			<div
				className="transfer__modal-inner transfer"
				style={{ height: '93vh' }}
			>
				<div className="transfer__top">
					<h2 className="transfer__title title">Форма обратной связи</h2>
					<form className="transfer__form">
						<ul className="transfer__list">
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="personalNumber">
									Номер лицевого счета
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											style={{ border: '2px solid black' }}
											className={cn('transfer__input', {
												_error: formik.errors.personalNumber,
											})}
											id="personalNumber"
											name="personalNumber"
											value={getInputValue('personalNumber')}
											onFocus={() => setInputName('personalNumber')}
											placeholder={'123456789012'}
											onChange={onChangeInput}
											max={12}
											min={8}
											maxLength={12}
										/>
									</div>
									<div className="transfer__input-text">
										Введите 8 или 12 цифр лицевого счета
									</div>
								</div>
								<div className="transfer__input-error">
									{formik.errors.personalNumber && formik.errors.personalNumber}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="phone">
									Номер телефона
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											style={{ border: '2px solid black' }}
											className={cn('transfer__input', {
												_error: formik.errors.phone,
											})}
											id="phone"
											name="phone"
											value={getInputValue('phone')}
											onFocus={() => setInputName('phone')}
											onChange={onChangeInput}
											placeholder="+7 (999) 999-99-99"
											maxLength={12}
											max={12}
										/>
									</div>
									<div className="transfer__input-text">
										Введите ваш контактный телефон
									</div>
								</div>
								<div className="transfer__input-error">
									{formik.errors.phone && formik.errors.phone}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="name">
									Контактное лицо
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											style={{ border: '2px solid black' }}
											className={cn('transfer__input', {
												_error: formik.errors.name,
											})}
											id="name"
											name="name"
											value={getInputValue('name')}
											onFocus={() => setInputName('name')}
											placeholder="Иван"
											onChange={onChangeInput}
										/>
									</div>
									<div className="transfer__input-text">Контактное лицо</div>
								</div>
								<div className="transfer__input-error">
									{formik.errors.name && formik.errors.name}
								</div>
							</li>
						</ul>
					</form>
				</div>
				<div
					className={`transfer__bottom   ${typeKeyboard !== 'numbers' && '_text'}`}
				>
					<Keyboard
						keyboardRef={(r) => (keyboard.current = r)}
						inputName={inputName}
						onChangeAll={onChangeAll}
						onKeyPress={onKeyPress}
						layoutName={
							typeKeyboard === 'text'
								? formik.values['name'] === undefined ||
									formik.values['name'].length == 0
									? 'shift'
									: 'default'
								: 'default'
						}
						layout={
							typeKeyboard === 'numbers'
								? inputName === 'phone'
									? layoutStPhone
									: layoutSt
								: layoutLettersName
						}
						display={
							typeKeyboard === 'numbers' ? displaySt : displayLettersName
						}
						buttonTheme={buttonTheme}
						maxLength={{
							personalNumber: 12,
							phone: 12,
						}}
					/>
				</div>
			</div>
		</Modal>
	)
}
