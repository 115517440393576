import { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { isCallModalIsOpen } from 'store/appReducer'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { CallModal, FeedbackForm, TimeoutProvider } from '@/components'

export const MainLayout = () => {
	const { callModalIsOpen, terminal_id, init } = useAppSelector(
		(state) => state.app
	)
	const dispatch = useAppDispatch()
	const location = useLocation()
	const [openModal, setOpenModal] = useState<boolean>(false)

	const switchCallMode = () => {
		const path = location.pathname
		if (path === '/' || path === '/begin') {
			return '_begin'
		}
		if (path.includes('service')) {
			return '_service'
		}
		return 'error'
	}

	useEffect(() => {
		if (init && terminal_id !== '') {
			const socket = new WebSocket(
				`https://arm.photondev.ru/astip_api/terminal_call/subscribe?TerminalId=${terminal_id}`
			)

			if (!callModalIsOpen) {
				try {
					socket.onmessage = async (msg: any) => {
						if (msg.data !== 'null') {
							dispatch(isCallModalIsOpen(true))
						}
					}
				} catch (error) {
					console.log(error)
					dispatch(isCallModalIsOpen(false))
				}
			}

			return () => {
				if (socket?.readyState !== 3) {
					socket.close()
					dispatch(isCallModalIsOpen(false))
				}
			}
		}
	}, [init, terminal_id])

	const closeModal = () => {
		dispatch(isCallModalIsOpen(false))
	}

	return (
		<>
			<TimeoutProvider>
				<Outlet />
			</TimeoutProvider>
			{callModalIsOpen && (
				<CallModal
					close={closeModal}
					open={callModalIsOpen}
					addingClass={switchCallMode}
					setOpenModal={setOpenModal}
				/>
			)}
			<FeedbackForm openModal={openModal} setOpenModal={setOpenModal} />
		</>
	)
}
