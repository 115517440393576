import { HOST_WEBRTC } from 'configs/url'
import { useAppSelector } from 'store/hooks'

export const CallPage = () => {
	const { terminal_id } = useAppSelector((state) => state.app)

	return (
		<div className="callPage">
			<div className="callPage__title title">Звонок оператору</div>
			<iframe
				src={`${HOST_WEBRTC}/${terminal_id}`}
				style={{ width: '100%', height: '100%' }}
				allow="camera; microphone; fullscreen"
				loading="lazy"
			></iframe>
		</div>
	)
}
