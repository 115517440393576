import React from 'react'

import icon from '../assets/images/maintenance.svg'

export default function TechnicalWork() {
	return (
		<section className="technical-work container">
			<div className="technical-work__title title">Технические работы</div>
			<div className="technical-work__subtitle">
				Приносим извинения, на данный момент <br /> проводятся технические
				работы...
			</div>
			<img src={icon} className="technical-work__img" />
		</section>
	)
}
