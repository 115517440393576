import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import cn from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import * as Yup from 'yup'

import {
	RECEIVE_PATH,
	START_PATH,
	buttonTheme,
	displaySt,
	layoutSt,
	layoutStPhone,
} from '../utils'

type InputName = 'phone' | 'personalNumber' | 'indications'

interface Form {
	phone: string
	personalNumber: string
	indications: string
}

const SignupSchema = Yup.object().shape({
	personalNumber: Yup.string()
		.min(8, 'Не менее 8 символов')
		.max(12, 'Не более 12 символов')
		.matches(/^([0-9]{8}|[0-9]{12})$/i, '8 или 12 цифр'),
	phone: Yup.string()
		.matches(
			/^((\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/gm,
			'Неверный номер телефона'
		)
		.min(12, 'Не менее 12 символов'),
	indications: Yup.string().max(5, 'Не более 5 символов'),
})

export const Transfer = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [open, setOpen] = useState(false)
	const [openErrorModal, setOpenErrorModal] = useState(false)
	const [inputs, setInputs] = useState<any>({})
	const [inputName, setInputName] = useState<InputName>('personalNumber')
	const keyboard = useRef<any>()

	const formik = useFormik<Form>({
		initialValues: {
			personalNumber: '',
			phone: '',
			indications: '',
		},
		validationSchema: SignupSchema,
		onSubmit: () => {},
	})

	const saveValue = (inputs: any) => {
		if (inputName !== 'phone') {
			formik.setValues({
				...inputs,
				phone: inputs['phone']?.replace(
					/^\+?7?(\d{3})(\d{3})(\d{2})(\d{2})$/,
					'+7 ($1) $2-$3-$4'
				),
			})
			setInputs({
				...inputs,
				phone: inputs['phone']?.replace(
					/^\+?7?(\d{3})(\d{3})(\d{2})(\d{2})$/,
					'+7 ($1) $2-$3-$4'
				),
			})
		} else if (inputName === 'phone') {
			formik.setValues({
				...inputs,
				phone: inputs['phone']
					?.trim()
					?.replace('(', '')
					?.replace(')', '')
					?.replaceAll('-', '')
					?.replaceAll(' ', ''),
			})
			setInputs({
				...inputs,
				phone: inputs['phone']
					?.trim()
					?.replace('(', '')
					?.replace(')', '')
					?.replaceAll('-', '')
					?.replaceAll(' ', ''),
			})
		}
	}

	const onChangeAll = (inputs: any) => saveValue(inputs)

	const onKeyPress = (button: any) => {
		if (button === '{enter}') {
			const values = formik.values,
				errorsForm = formik.errors

			if (
				formik.isValid &&
				values['personalNumber'] &&
				values['phone'] &&
				values['indications']
			) {
				setOpen(true)
			} else {
				const errors: any = {}
				if (
					values.personalNumber === undefined ||
					values.personalNumber === ''
				) {
					errors.personalNumber = 'Поле обязательно для заполнение'
				}

				if (values.phone === undefined || values.phone === '') {
					errors.phone = 'Поле обязательно для заполнение'
				}

				if (values.indications === undefined || values.indications === '') {
					errors.indications = 'Поле обязательно для заполнение'
				}

				formik.setErrors({ ...errorsForm, ...errors })
				setOpenErrorModal(true)
			}
		}
	}

	const onChangeInput = (event: any) => {
		const inputVal = event?.target?.value

		setInputs({
			...inputs,
			[inputName]: inputVal,
		})
		formik.setValues({
			...inputs,
			[inputName]: inputVal,
		})

		keyboard.current.setInput(inputVal)
	}

	const getInputValue = (inputName: any) => {
		return inputs[inputName] || ''
	}

	useEffect(() => {
		saveValue(inputs)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputName])

	useEffect(() => {
		setInputName('personalNumber')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<div className="transfer">
				<div className="transfer__top">
					<Button
						className="transfer__info"
						type="primary"
						shape="circle"
						style={{ width: '50px', height: '50px' }}
						size="large"
						onClick={() => setIsModalOpen(true)}
						icon={<QuestionCircleOutlined style={{ fontSize: '26px' }} />}
					/>

					<h2 className="transfer__title title">Передать показания</h2>
					<form className="transfer__form">
						<ul className="transfer__list">
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="personalNumber">
									Номер лицевого счета
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={cn('transfer__input', {
												_error: formik.errors.personalNumber,
											})}
											id="personalNumber"
											name="personalNumber"
											value={getInputValue('personalNumber')}
											onFocus={() => setInputName('personalNumber')}
											placeholder={'123456789012'}
											onChange={onChangeInput}
											max={12}
											min={8}
											maxLength={12}
										/>
									</div>
									<div className="transfer__input-text">
										Введите 8 или 12 цифр лицевого счета
									</div>
								</div>
								<div className="transfer__input-error">
									{formik.errors.personalNumber && formik.errors.personalNumber}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="phone">
									Номер телефона
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={cn('transfer__input', {
												_error: formik.errors.phone,
											})}
											id="phone"
											name="phone"
											value={getInputValue('phone')}
											onFocus={() => setInputName('phone')}
											onChange={onChangeInput}
											placeholder="+7 (999) 999-99-99"
											maxLength={12}
											max={12}
										/>
									</div>
									<div className="transfer__input-text">
										Введите ваш контактный телефон
									</div>
								</div>
								<div className="transfer__input-error">
									{formik.errors.phone && formik.errors.phone}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="indications">
									Показания счётчика
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={cn('transfer__input', {
												_error: formik.errors.indications,
											})}
											id="indications"
											name="indications"
											value={getInputValue('indications')}
											onFocus={() => setInputName('indications')}
											placeholder={'12345'}
											onChange={onChangeInput}
											maxLength={5}
											max={5}
										/>
									</div>
									<div className="transfer__input-text">
										Введите текущие показания газового счётчика до запятой, не
										более 5 символов
									</div>
								</div>
								<div className="transfer__input-error">
									{formik.errors.indications && formik.errors.indications}
								</div>
							</li>
						</ul>
					</form>
				</div>
				<div className="transfer__bottom ">
					<Keyboard
						keyboardRef={(r) => (keyboard.current = r)}
						inputName={inputName}
						onChangeAll={onChangeAll}
						onKeyPress={onKeyPress}
						layout={inputName === 'phone' ? layoutStPhone : layoutSt}
						buttonTheme={buttonTheme}
						display={displaySt}
						maxLength={{
							phone: 12,
							personalNumber: 12,
							indications: 5,
						}}
					/>
				</div>
			</div>
			<Modal
				title="Передать показания информация"
				open={isModalOpen}
				width={800}
				onCancel={() => setIsModalOpen(false)}
				footer={() => (
					<Button
						type="primary"
						onClick={() => setIsModalOpen(false)}
						key={'back'}
					>
						Закрыть
					</Button>
				)}
			>
				<p>
					Сервис приема показаний счетчика в данном терминале является средством
					для приема показаний приборов учета газа от абонентов ООО «Газпром
					межрегионгаз Оренбург».
				</p>{' '}
				<p>
					Показания счетчиков абонентов, направленные в электронном виде в
					данном терминале принимаются к учету в соответствии с действующим
					законодательством. Информация о персональных данных абонентов,
					направивших показания приборов учета газа в электронном виде, хранится
					и обрабатывается в соответствии с требованиями Российского
					законодательства о защите персональных данных. С целью корректной
					обработки поступающих показаний приборов, просим указывать точные и
					полные данные. Обратите внимание, что все поля являются обязательными
					для заполнения. Ваш номер телефона может быть использован для
					уточнения введенных вами данных.
				</p>
			</Modal>
			<Modal
				open={open}
				onCancel={() => setOpen(false)}
				centered
				title="Показания переданы успешно"
				footer={() => (
					<div className="transfer__btn-wrap" key={'footer'}>
						<Link to={START_PATH} className="btn transfer__btn-modal">
							Вернуться на главную
						</Link>

						<Link to={RECEIVE_PATH} className="btn transfer__btn-modal">
							Получить счета на e-mail
						</Link>
					</div>
				)}
			>
				<div className="transfer__modal-top">
					<div className="transfer__modal-inner">
						<div className="transfer__modal-title">Лицевой счет:</div>
						<div className="transfer__modal-text">
							{inputs['personalNumber']}
						</div>
					</div>
					<div className="transfer__modal-inner">
						<div className="transfer__modal-title">Показания счетчика:</div>
						<div className="transfer__modal-text">{inputs['indications']}</div>
					</div>
				</div>
				<div className="transfer__modal-inner">
					<div className="transfer__modal-title">телефон</div>
					<div className="transfer__modal-text">{inputs['phone']}</div>
				</div>
			</Modal>
			<Modal
				open={openErrorModal}
				centered
				title="Внимание!"
				onCancel={(e) => {
					console.log(e)
					setOpenErrorModal(false)
				}}
				maskClosable={false}
				footer={() => (
					<div className="transfer__btn-wrap" key={'footer'}>
						<Button
							type="primary"
							className="btn transfer__btn-modal"
							onClick={() => setOpenErrorModal(false)}
						>
							Вернуться
						</Button>
					</div>
				)}
			>
				<div className="transfer__modal-error">
					<div className="transfer__modal-error-text">
						Не все данные заполнены корректно
					</div>
					<ul className="transfer__modal-error-list">
						{formik.errors.personalNumber ? (
							<li>
								<span>Лицевой счет: </span>
								<span>{formik.errors.personalNumber}</span>
							</li>
						) : (
							''
						)}
						{formik.errors.phone ? (
							<li>
								<span>Телефон: </span>
								<span>{formik.errors.phone}</span>
							</li>
						) : (
							''
						)}
						{formik.errors.indications ? (
							<li>
								<span>Показания: </span>
								<span>{formik.errors.indications}</span>
							</li>
						) : (
							''
						)}
					</ul>
				</div>
			</Modal>
		</>
	)
}
