import {
	ContainerOutlined,
	CreditCardOutlined,
	CustomerServiceOutlined,
	SnippetsOutlined,
	UserOutlined,
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { isCallModalIsOpen, setTypeCall } from 'store/appReducer'
import { useAppDispatch } from 'store/hooks'

import {
	GASIFICATION_PATH,
	MAIN_PATH,
	PAYMENT_PATH,
	PROFILE_PATH,
	RECEIVE_PATH,
	TRANSFER_PATH,
} from '@/utils'

import LogoGazification from '../assets/images/dogazblue.svg'
import Logo from '../assets/images/logo_main.png'

export const BeginPage = () => {
	const dispatch = useAppDispatch()
	const setCallModalIsOpen = () => {
		dispatch(isCallModalIsOpen(true))
		dispatch(setTypeCall('terminal'))
	}

	return (
		<div className="BeginPage">
			<div className="BeginPage__header">
				<Link to={MAIN_PATH} className="BeginPage__logo">
					<img className="BeginPage__logo-img" src={Logo} alt="logo" />
					<div className="BeginPage__logo-text">
						Интерактивный прием абонентов
					</div>
				</Link>
			</div>

			<div className="BeginPage__inner-wrap">
				<div className="BeginPage__inner-title">
					Выберите интересующую вас услугу
				</div>
				<ul className="BeginPage__inner">
					<li className="BeginPage__item">
						<Link to={TRANSFER_PATH} className="BeginPage__link">
							<div className="BeginPage__img-wrap">
								<SnippetsOutlined
									style={{ color: '#4195df', fontSize: '45px' }}
								/>
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">Передать показания</div>
								<div className="BeginPage__item-descr">
									Передайте показания газового счетчика через терминад
								</div>
							</div>
						</Link>
					</li>
					<li className="BeginPage__item">
						<Link to={PAYMENT_PATH} className="BeginPage__link">
							<div className="BeginPage__img-wrap">
								<CreditCardOutlined
									style={{ color: '#4195df', fontSize: '45px' }}
								/>
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">Оплата газа</div>
								<div className="BeginPage__item-descr">
									Оплатите услуги газа через терминал БЕЗ КОМИССИИ
								</div>
							</div>
						</Link>
					</li>
					<li className="BeginPage__item">
						<Link to={RECEIVE_PATH} className="BeginPage__link">
							<div className="BeginPage__img-wrap">
								<ContainerOutlined
									style={{ color: '#4195df', fontSize: '45px' }}
								/>
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">
									Получить Счета на E-mail
								</div>
								<div className="BeginPage__item-descr">
									Оформите заявку на получение счета за газ на электронную почту
								</div>
							</div>
						</Link>
					</li>
					<li className="BeginPage__item">
						<Link to={PROFILE_PATH} className="BeginPage__link">
							<div className="BeginPage__img-wrap">
								<UserOutlined style={{ color: '#4195df', fontSize: '45px' }} />
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">Личный кабинет</div>
								<div className="BeginPage__item-descr">
									Узнайте о возможностях личного кабинета
								</div>
							</div>
						</Link>
					</li>
					<li className="BeginPage__item">
						<Link to={GASIFICATION_PATH} className="BeginPage__link">
							<div className="BeginPage__img-wrap">
								<img className="BeginPage__img" src={LogoGazification} alt="" />
							</div>
							<div className="BeginPage__right">
								<div className="BeginPage__item-title">Догазификация</div>
								<div className="BeginPage__item-descr">
									Подключите свое домовладение к газовой сети на льготных
									условиях
								</div>
							</div>
						</Link>
					</li>
				</ul>
				<button
					onClick={setCallModalIsOpen}
					className="BeginPage__call BeginPage__link"
				>
					<CustomerServiceOutlined
						style={{ color: '#4195df', fontSize: '65px' }}
					/>

					<span>Видеозвонок оператору</span>
				</button>
			</div>
		</div>
	)
}
