import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import cn from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import { loadingStatus } from 'store/appReducer'
import { useAppDispatch } from 'store/hooks'
import * as Yup from 'yup'

import { payAPI } from '@/api'

import {
	START_PATH,
	buttonTheme,
	displayLetters,
	displaySt,
	layoutLetters,
	layoutSt,
} from '../utils'

type InputName = 'summa' | 'personalNumber' | 'mail'

interface Form {
	summa: string
	personalNumber: string
	mail: string
}

const SignupSchema = Yup.object().shape({
	personalNumber: Yup.string()
		.min(8, 'Не менее 8 символов')
		.max(12, 'Не более 12 символов')
		.matches(/^([0-9]{8}|[0-9]{12})$/i, '8 или 12 цифр'),
	summa: Yup.string().matches(/^[1-9]\d*$/gm, 'Сумма не может начинаться с 0'),
	mail: Yup.string().matches(
		/^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@([-A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/g,
		'Неверный email'
	),
})

export const Cardpage = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
	const [open, setOpen] = useState(false)
	const [openErrorModal, setOpenErrorModal] = useState(false)
	const [inputs, setInputs] = useState<any>({})
	const [inputName, setInputName] = useState<InputName>('personalNumber')
	const [typeKeyboard, setTypeKeyboard] = useState<'numbers' | 'text'>(
		'numbers'
	)
	let navigate = useNavigate()
	const dispatch = useAppDispatch()
	const keyboard = useRef<any>()

	const formik = useFormik<Form>({
		initialValues: {
			personalNumber: '',
			summa: '',
			mail: '',
		},
		validationSchema: SignupSchema,
		onSubmit: () => {},
	})

	const saveValue = (inputs: any) => {
		formik.setValues({
			...inputs,
		})
		setInputs({
			...inputs,
		})
	}

	const onChangeAll = (inputs: any) => saveValue(inputs)

	const onSubmit = async (values: Form) => {
		try {
			dispatch(loadingStatus(true))
			const response = await payAPI.payCard({
				operation_name: values.personalNumber,
				amount: String(+values.summa * 100),
				client_mail: values.mail ? values.mail : undefined,
			})
			console.log(response)
			setOpen(true)
		} catch (e) {
			console.error(e)
			setIsErrorModalOpen(true)
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const onKeyPress = (button: any) => {
		if (button === '{enter}') {
			const values = formik.values
			const errorsForm = formik.errors

			if (formik.isValid && values['summa'] && values['personalNumber']) {
				onSubmit(values)
			} else {
				const errors: any = {}
				if (!values.personalNumber) {
					errors.personalNumber = 'Поле обязательно для заполнение'
				}

				if (!values.summa) {
					errors.summa = 'Поле обязательно для заполнение'
				}
				setOpenErrorModal(true)
				formik.setErrors({ ...errorsForm, ...errors })
			}
		}
	}

	const onChangeInput = (event: any) => {
		const inputVal = event?.target?.value

		setInputs({
			...inputs,
			[inputName]: inputVal,
		})
		formik.setValues({
			...inputs,
			[inputName]: inputVal,
		})

		keyboard.current.setInput(inputVal)
	}

	const getInputValue = (inputName: any) => {
		return inputs[inputName] || ''
	}

	useEffect(() => {
		saveValue(inputs)

		if (inputName === 'mail') {
			setTypeKeyboard('text')
		} else {
			setTypeKeyboard('numbers')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputName])

	useEffect(() => {
		setInputName('personalNumber')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<>
			<div className={`transfer payment ${typeKeyboard === 'text' && '_text'}`}>
				<div className="transfer__top">
					<Button
						className="transfer__info"
						type="primary"
						shape="circle"
						style={{ width: '65px', height: '65px' }}
						size="large"
						onClick={() => setIsModalOpen(true)}
						icon={<QuestionCircleOutlined style={{ fontSize: '40px' }} />}
					></Button>

					<h2 className="transfer__title title">Оплата газа картой</h2>
					<form className="transfer__form">
						<ul className="transfer__list">
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="personalNumber">
									Номер лицевого счета
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={cn('transfer__input', {
												_error: formik.errors.personalNumber,
											})}
											id="personalNumber"
											name="personalNumber"
											value={getInputValue('personalNumber')}
											onFocus={() => setInputName('personalNumber')}
											placeholder={'123456789012'}
											onChange={onChangeInput}
											max={12}
											min={8}
											maxLength={12}
										/>
									</div>
									<div className="transfer__input-text">
										Введите 8 или 12 цифр лицевого счета
									</div>
								</div>
								<div className="transfer__input-error">
									{formik.errors.personalNumber && formik.errors.personalNumber}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="summa">
									Сумма платежа
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={cn('transfer__input', {
												_error: formik.errors.summa,
											})}
											id="summa"
											name="summa"
											value={getInputValue('summa')}
											onFocus={() => setInputName('summa')}
											onChange={onChangeInput}
											placeholder="123456789012"
										/>
									</div>
									<div className="transfer__input-text">
										Введите сумму плтежа с точностью до рубля
									</div>
								</div>
								<div className="transfer__input-error">
									{formik.errors.summa && formik.errors.summa}
								</div>
							</li>
							<li className="transfer__item">
								<label className="transfer__label" htmlFor="mail">
									Электронная почта
								</label>
								<div className="transfer__input-wrap">
									<div className="transfer__input-inner">
										<input
											className={cn('transfer__input', {
												_error: formik.errors.mail,
											})}
											id="mail"
											name="mail"
											value={getInputValue('mail')}
											onFocus={() => setInputName('mail')}
											placeholder="example@example.ru"
											onChange={onChangeInput}
										/>
									</div>
									<div className="transfer__input-text">
										Почта для отправки чека об операции. Если вы оставите поле
										пустым, ваш чек будет храниться в Обществе
									</div>
								</div>
								<div className="transfer__input-error">
									{formik.errors.mail && formik.errors.mail}
								</div>
							</li>
						</ul>
					</form>
				</div>

				<div className="transfer__bottom ">
					<Keyboard
						keyboardRef={(r) => (keyboard.current = r)}
						inputName={inputName}
						onChangeAll={onChangeAll}
						onKeyPress={onKeyPress}
						layout={typeKeyboard === 'numbers' ? layoutSt : layoutLetters}
						display={typeKeyboard === 'numbers' ? displaySt : displayLetters}
						buttonTheme={buttonTheme}
						maxLength={{
							personalNumber: 12,
						}}
					/>
				</div>
			</div>
			<Modal
				title="Оплата с помощью карты"
				open={isModalOpen}
				width={1000}
				className="modal-text"
				onCancel={() => setIsModalOpen(false)}
				footer={() => (
					<Button type="primary" onClick={() => setIsModalOpen(false)}>
						Закрыть
					</Button>
				)}
			>
				<p>
					Оплата банковской картой
					<br />
					БЕЗ КОМИССИИ
				</p>
			</Modal>
			<Modal
				open={open}
				onCancel={() => {
					setOpen(false)
					navigate(START_PATH)
				}}
				centered
				className="modal-text"
				title="Оплата прошла успешно"
				footer={() => (
					<div className="transfer__btn-wrap">
						<Link to={START_PATH} className="btn transfer__btn-modal">
							Вернуться на главную
						</Link>
					</div>
				)}
			>
				<div className="transfer__modal-top">
					<div className="transfer__modal-inner">
						<div className="transfer__modal-title">Лицевой счет:</div>
						<div className="transfer__modal-text">
							{formik.values.personalNumber}
						</div>
					</div>
					<div className="transfer__modal-inner">
						<div className="transfer__modal-title">Сумма платежа:</div>
						<div className="transfer__modal-text">{formik.values.summa}</div>
					</div>
				</div>
				<div className="transfer__modal-inner">
					<div className="transfer__modal-title">
						Ваш чек отправлен по адресу:
					</div>
					<div className="transfer__modal-text">{formik.values.mail}</div>
				</div>
			</Modal>
			<Modal
				open={openErrorModal}
				centered
				title="Внимание!"
				onCancel={(e) => {
					console.log(e)
					setOpenErrorModal(false)
				}}
				maskClosable={false}
				footer={() => (
					<div className="transfer__btn-wrap" key={'footer'}>
						<Button
							type="primary"
							className="btn transfer__btn-modal"
							onClick={() => setOpenErrorModal(false)}
						>
							Вернуться
						</Button>
					</div>
				)}
			>
				<div className="transfer__modal-error">
					<div className="transfer__modal-error-text">
						Не все данные заполнены корректно
					</div>
					<ul className="transfer__modal-error-list">
						{formik.errors.personalNumber ? (
							<li>
								<span>Лицевой счет: </span>
								<span>{formik.errors.personalNumber}</span>
							</li>
						) : (
							''
						)}
						{formik.errors.summa ? (
							<li>
								<span>Сумма: </span>
								<span>{formik.errors.summa}</span>
							</li>
						) : (
							''
						)}
						{formik.errors.mail ? (
							<li>
								<span>Почта: </span>
								<span>{formik.errors.mail}</span>
							</li>
						) : (
							''
						)}
					</ul>
				</div>
			</Modal>
			<Modal
				open={isErrorModalOpen}
				centered
				title="Ошибка оплаты"
				onCancel={(e) => {
					console.log(e)
					setIsErrorModalOpen(false)
				}}
				footer={() => (
					<div className="transfer__btn-wrap" key={'footer'}>
						<Button
							type="primary"
							className="btn transfer__btn-modal"
							onClick={() => setIsErrorModalOpen(false)}
						>
							назад
						</Button>
					</div>
				)}
			>
				<div className="transfer__modal-info">Произошла ошибка оплаты</div>
			</Modal>
		</>
	)
}
