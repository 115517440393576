import TechnicalWork from 'pages/TechnicalWok'
import { createHashRouter } from 'react-router-dom'

import { Error } from '@/components'

import {
	BeginPage,
	CallPage,
	Cardpage,
	Gasification,
	Payment,
	Profile,
	Receive,
	SbpPage,
	StartPage,
	Transfer,
} from '@/pages'

import {
	CALL_PATH,
	ERROR_PATH,
	GASIFICATION_PATH,
	MAIN_PATH,
	PAYMENT_CARD_PATH,
	PAYMENT_PATH,
	PAYMENT_SBP_PATH,
	PROFILE_PATH,
	RECEIVE_PATH,
	SERVICE_PATH,
	START_PATH,
	TECHNICAL_PATH,
	TRANSFER_PATH,
} from '@/utils'

import { MainLayout, ServiceLayout } from './layout'

const router = createHashRouter([
	{
		path: MAIN_PATH,
		element: <MainLayout />,
		errorElement: <Error />,
		children: [
			{
				path: MAIN_PATH,
				element: <StartPage />,
				errorElement: <Error />,
			},
			{
				path: START_PATH,
				element: <BeginPage />,
				errorElement: <Error />,
			},
			{
				path: CALL_PATH,
				element: <CallPage />,
				errorElement: <Error />,
			},
			{
				path: SERVICE_PATH,
				element: <ServiceLayout />,
				errorElement: <Error />,

				children: [
					{
						path: TRANSFER_PATH,
						element: <Transfer />,
						errorElement: <Error />,
					},
					{
						path: PAYMENT_PATH,
						element: <Payment />,
						errorElement: <Error />,
					},
					{
						path: PAYMENT_SBP_PATH,
						element: <SbpPage />,
						errorElement: <Error />,
					},
					{
						path: PAYMENT_CARD_PATH,
						element: <Cardpage />,
						errorElement: <Error />,
					},
					{
						path: RECEIVE_PATH,
						element: <Receive />,
						errorElement: <Error />,
					},
					{
						path: PROFILE_PATH,
						element: <Profile />,
						errorElement: <Error />,
					},
					{
						path: GASIFICATION_PATH,
						element: <Gasification />,
						errorElement: <Error />,
					},
				],
			},
		],
	},

	{
		path: ERROR_PATH,
		element: <Error />,
	},
	{
		path: TECHNICAL_PATH,
		element: <TechnicalWork />,
	},
])

export default router
