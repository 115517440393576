export const MAIN_PATH = '/'
export const START_PATH = '/begin'
export const SERVICE_PATH = '/service'
export const CALL_PATH = '/call'
export const TRANSFER_PATH = '/service/transfer'
export const PAYMENT_PATH = '/service/payment'
export const PAYMENT_SBP_PATH = '/service/payment/sbp'
export const PAYMENT_CARD_PATH = '/service/payment/card'
export const RECEIVE_PATH = '/service/receive'
export const PROFILE_PATH = '/service/profile'
export const GASIFICATION_PATH = '/service/gasification'
export const LOGIN_PATH = '/login'
export const ERROR_PATH = '/404'
export const typeAppMobile = 'mob'
export const typeAppDesktop = 'desk'
export const TECHNICAL_PATH = '/technical-work'
