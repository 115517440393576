export const layoutSt = {
	default: ['1 2 3 {bksp}', '4 5 6 {enter}', '7 8 9 ', ' 0  '],
}
export const layoutStPhone = {
	default: ['1 2 3 {bksp}', '4 5 6 {enter}', '7 8 9 ', '+ 0  '],
}
export const displaySt = {
	'{bksp}': 'Стереть ⌫',
	'{enter}': 'Отправить ↵',
}

export const layoutLetters = {
	default: [
		'1 2 3 4 5 6 7 8 9 0 - _ {bksp}',
		'q w e r t y u i o p @ {enter}',
		'a s d f g h j k l .ru @yandex.ru @mail.ru',
		'z x c v b n m . @rambler.ru',
	],
}
export const layoutLettersName = {
	default: [
		'й ц у к е н г ш щ з х ъ {bksp}',
		'ф ы в а п р о л д ж э {enter}',
		'я ч с м м и т ь б ю ',
	],
	shift: [
		'Й Ц У К Е Н Г Ш Щ З Х Ъ {bksp}',
		'Ф Ы В А П Р О Л Д Ж Э {enter}',
		'Я Ч С М И Т Ь Б Ю ',
	],
}

export const domainsLayout = {
	default: [
		'{bksp}',
		'{enter}',
		'@yandex.ru @mail.ru',
		'@rambler.ru @gmail.com',
	],
}
export const displayLetters = {
	'{bksp}': 'Стереть ⌫',
	'{enter}': 'Отправить  ↵',
	'{.com}': '.com',
	'{.ru}': '.ru',
	'{@yandex.ru}': '@yandex.ru',
	'{@rambler.ru}': '@rambler.ru',
	'{@mail.ru}': '@mail.ru',
	'{@gmail.com}': '@gmail.com',
	'{//}': ' ',
}

export const displayLettersName = {
	'{bksp}': 'Стереть ⌫',
	'{enter}': 'Отправить  ↵',
	'{shift}': 'Shift',
	'{//}': ' ',
}

export const buttonTheme = [
	{
		class: 'btn-primary',
		buttons: '{enter}',
	},
]
